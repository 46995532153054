<template>
  <div class="animated fadeIn">
    <v-col cols="12">
      <v-card-title
        style="background-color: transparent !important"
        class="ps-0 pe-0"
      >
        <v-row style="padding-left: 10px !important">
          <v-col class="text-right">
            <h3>مدیریت بیماران</h3>
          </v-col>
          <v-col>
            <v-btn
              style="float: left"
              class="primary-btn"
              @click="gotoNewPatient()"
            >
              <v-icon left>person_add</v-icon>
              افزودن
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card class="cards pa-3">
        <v-card-text>
          <div>
            <v-row align-v="end" v-if="role == 'admin'">
              <v-col>
                <v-btn
                  style="float: left"
                  :loading="isBusy"
                  class="mb-2 py-5 secondary-btn"
                  @click="patientsOutput()"
                  >دریافت اکسل لیست کلیه بیماران</v-btn
                >
                <vue-excel-xlsx
                  v-show="false"
                  ref="excelBtn"
                  :data="excelItems"
                  :columns="excelFields"
                  :filename="'لیست بیماران'"
                  :sheetname="currentDate"
                >
                  دریافت اکسل
                </vue-excel-xlsx>
              </v-col>
            </v-row>
            <v-row class="inputs-row pb-5">
              <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                <v-text-field
                  label="نام"
                  v-model="filterName"
                  dense
                  outlined
                  class="search-input"
                  @keyup.enter.native="
                    (filterName != '' ||
                      filterNatCode != '' ||
                      filterCode != '') &&
                    !Busy
                      ? getPatients()
                      : ''
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                <v-text-field
                  type="number"
                  label="کدملی"
                  no-wheel
                  dense
                  outlined
                  class="search-input"
                  v-model="filterNatCode"
                  @keyup.enter.native="
                    (filterName != '' ||
                      filterNatCode != '' ||
                      filterCode != '') &&
                    !Busy
                      ? getPatients()
                      : ''
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                <v-text-field
                  type="number"
                  label="کد اشتراک"
                  no-wheel
                  dense
                  outlined
                  v-model="filterCode"
                  @keyup.enter.native="
                    (filterName != '' ||
                      filterNatCode != '' ||
                      filterCode != '') &&
                    !Busy
                      ? getPatients()
                      : ''
                  "
                  class="search-input"
                ></v-text-field>
              </v-col>
              <v-btn
                class="secondary-btn filter-btn ma-3"
                @click="getPatients()"
                :disabled="
                  (filterName == '' &&
                    filterNatCode == '' &&
                    filterCode == '') ||
                  Busy
                "
              >
                اعمال فیلتر
                <v-icon right>tune</v-icon>
              </v-btn>
            </v-row>
            <v-text-field
              v-model="Filter"
              prepend-inner-icon="mdi-magnify"
              label="جستجو"
              filled
              rounded
              single-line
              clearable
              hide-details
              class="text-right mb-2 search-input dr-search ms-0 me-0"
            ></v-text-field>
            <b-table
              responsive
              show-empty
              :fields="computedFields"
              :items="Items"
              empty-text="بیماری برای نمایش وجود ندارد"
              empty-filtered-text="بیماری برای نمایش وجود ندارد"
              :busy="Busy"
              :filter="Filter"
              :filterIncludedFields="['name']"
              @filtered="onFiltered"
              :current-page="CurrentPage"
              :per-page="PerPage"
            >
              <template v-slot:head()="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.label }}
                </div>
              </template>
              <template v-slot:cell()="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.value }}
                </div>
              </template>
              <template v-slot:cell(index)="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.index + PerPage * (CurrentPage - 1) + 1 }}
                </div>
              </template>
              <template v-slot:cell(operation)="data">
                <div style="text-align: center; vertical-align: middle">
                  <v-btn
                    class="primary-btn pa-2"
                    :to="
                      '/' +
                      (role == 'reception' ? 'reception' : 'admin') +
                      '/editPatient/' +
                      data.item.id
                    "
                    ><v-icon> edit</v-icon></v-btn
                  >
                </div>
              </template>
              <div slot="table-busy" class="text-center primary--text my-2">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  class="align-middle"
                ></v-progress-circular>
              </div>
            </b-table>
            <v-pagination
              v-model="CurrentPage"
              :length="Math.ceil(TotalRows / PerPage)"
              :total-visible="5"
              @input="oldCurrentPage = CurrentPage"
              prev-icon="arrow_back"
              next-icon="arrow_forward"
              style="float: center"
            ></v-pagination>
            <v-select
              label="تعداد در هر صفحه:"
              style="width: 150px"
              v-model="PerPage"
              :items="perPageOptions"
              item-text="text"
              item-value="value"
              @change="calCurrent"
            ></v-select>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </div>
</template>
<script>
import moment from "moment-jalaali";

export default {
  data() {
    return {
      CurrentPage: 1,
      oldCurrentPage: 1,
      oldPerPage: 10,
      PerPage: 10,
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      TotalRows: "",
      Filter: "",
      Busy: false,
      isBusy: false,
      filterCode: "",
      filterName: "",
      filterNatCode: "",
      role: "",
      Fields: [
        { key: "index", label: "#" },
        { key: "name", label: "نام و نام خانوادگی" },
        { key: "code", label: "کد اشتراک" },
        { key: "mobile", label: "شماره موبایل" },
        { key: "insurance", label: "نوع بیمه" },
        { key: "gender", label: "جنسیت" },
        { key: "operation", label: "مدیریت " },
      ],
      excelFields: [
        { field: "name", label: "نام و نام خانوادگی" },
        { field: "code", label: "کد اشتراک" },
        { field: "mobile", label: "شماره موبایل" },
        { field: "createdAt", label: "تاریخ اولین مراجعه" },
        { field: "insurance", label: "نوع بیمه" },
        { field: "gender", label: "جنسیت" },
      ],
      Items: [],
      excelItems: [],
      currentDate: moment(new Date()).format("jYYYY-jMM-jDD"),
    };
  },
  computed: {
    computedFields() {
      if (this.deviceType == "mobile") {
        return [
          { key: "name", label: "نام و نام خانوادگی" },
          { key: "mobile", label: "شماره موبایل" },
          { key: "operation", label: "مدیریت " },
        ];
      } else {
        return [
          { key: "index", label: "#" },
          { key: "name", label: "نام و نام خانوادگی" },
          { key: "code", label: "کد اشتراک" },
          { key: "mobile", label: "شماره موبایل" },
          { key: "insurance", label: "نوع بیمه" },
          { key: "gender", label: "جنسیت" },
          { key: "operation", label: "مدیریت " },
        ];
      }
    },
  },
  methods: {
    calCurrent() {
      this.CurrentPage = Math.ceil(
        (this.oldPerPage * (this.oldCurrentPage - 1) + 1) / this.PerPage
      );
      this.oldPerPage = this.PerPage;
      this.oldCurrentPage = this.CurrentPage;
    },
    gotoNewPatient() {
      let role = this.role == "reception" ? "reception" : "admin";
      this.$router.push("/" + role + "/newPatient/");
    },
    patientsOutput() {
      //NOTE getting patients excel
      this.isBusy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/patients/export",
          {},
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.excelItems = res.data;
            this.isBusy = false;
            setTimeout(() => {
              this.$refs.excelBtn.$el.click();
            }, 300);
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.isBusy = false;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.isBusy = false;
        });
    },
    getPatients() {
      this.Busy = true;
      this.CurrentPage = 1;
      //getting patient info from server and set it in our data
      this.$http
        .post(
          this.baseUrl + "/clinic/patients/filterAll",
          {
            name: this.filterName,
            natCode: this.filterNatCode,
            code: this.filterCode,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.Busy = false;
          if (res.status == 200) {
            this.Items = res.data;
            this.TotalRows = this.Items.length;
            this.CurrentPage = 1;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.Busy = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.TotalRows = filteredItems.length;
      this.CurrentPage = 1;
    },
  },
  mounted() {
    this.role = localStorage.getItem("role");
  },
};
</script>
